import { SIGNIN_SUCCESS, SIGNOUT_SUCCESS, UPDATE_PROFILE } from '../_types';
import * as func from 'utils/functions';

export function signInSuccess(token, data) {
    return dispatch => {
        dispatch({
            type: SIGNIN_SUCCESS,
            payload: { data, token, },
        });
    }
};

export function signOutSuccess(path = '/') {
    return dispatch => {
        // message.success('success', 'You are now logged out!');
        func.delStorage('user');
        func.delStorage('token');
        func.delStorage('awardRow');
        func.delStorage('organisation');
        func.delStorage('organisationRow');
        func.redirect(path);
        dispatch({ type: SIGNOUT_SUCCESS });
    }
};



export function profileUpdated(payload) {
    return dispatch => {
        func.setStorageJson('user', payload);
        dispatch({ type: UPDATE_PROFILE, payload });
    }
};