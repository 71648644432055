import React from 'react';
import { Button, Dropdown } from 'antd';
import { func } from 'utils';


const EventCard = (props) => {
    const { row, gotoPage, deleteRow, _data: { settings, organisation }, setDetails, lowTicket } = props;

    return (
        <React.Fragment>
            <div key={row.id} className="col-lg-4">
                <div className="card event-card animate__animated animate__fadeIn">
                    <div className="card-header">
                        <div className="media d-flex justicy-content-between align-items-center">
                            <div className="media-body text-truncate">
                                <h3 className="mt-0 text-truncate" style={{ width: '95%' }}>{row.name}</h3>
                                <p>
                                    {row.status ? 'Published' : 'Not published'} &nbsp;•&nbsp;&nbsp;
                                    <span>{row.preferences.ticket_fee || settings[row.organisation.country.code].ticket_fee}% service fee</span> &nbsp;•&nbsp;&nbsp;
                                    <b className="primary">{row.code}</b>
                                </p>
                            </div>
                            <Dropdown trigger={['click']} placement="bottomRight" menu={{
                                items: [
                                    {
                                        key: 'edit', status: [0, 1],
                                        label: (<div onClick={() => gotoPage(row)}>Edit</div>),
                                    },
                                    {
                                        key: 'details', status: [0, 1],
                                        label: (<div onClick={() => setDetails(row)}>Details</div>),
                                    },
                                    {
                                        key: 'view', status: [0, 1],
                                        label: (<div onClick={() => window.open(row.url, '_blank')}>View</div>),
                                    },
                                    {
                                        key: 'transfer', status: [0, 1],
                                        label: (<div onClick={() => gotoPage(row, '/transfer')}>Transfer</div>),
                                    },
                                    {
                                        key: 'payments', status: [0, 1],
                                        label: (<div onClick={() => gotoPage(row, '/payments')}>Payments</div>),
                                    },
                                    {
                                        key: 'tickets', status: [0, 1],
                                        label: (<div onClick={() => gotoPage(row, '/tickets')}>Tickets</div>),
                                    },
                                    {
                                        key: 'delete', status: [0],
                                        label: (<div className="danger" onClick={() => deleteRow(row)}>Delete</div>),
                                    }
                                ].filter(item => item.status.includes(row.status))
                            }}>
                                <Button type="ghost" shape="circle" icon={<i className="fa fa-ellipsis-h"></i>} />
                            </Dropdown>
                        </div>
                    </div>

                    <div className="event-card-img">
                        <div className="bg-img" style={{ backgroundImage: `url(${row.logo_link})`, width: '100%', height: 300 }} />
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-12 mb-3">
                                <h5 className="mb-0">Pricing type</h5>
                                <p className="text-capitalize">
                                    {`Paid (${organisation.country.currency} ${func.numberFormat(lowTicket(row.tickets).price, 2)}${row.tickets.length > 1 ? '+' : ''})`}
                                </p>
                            </div>
                            <div className="col-6 col-lg-6 mb-3">
                                <h5 className="mb-0">Sales</h5>
                                <b className="text-muted">
                                    {func.numberFormat(row.tickets.reduce(function (sum, current) {
                                        return sum + +(current.sales ?? 0);
                                    }, 0))}/
                                    {func.numberFormat(row.tickets.reduce(function (sum, current) {
                                        return sum + +(current.quantity ?? 0);
                                    }, 0))}
                                </b>
                            </div>
                            <div className="col-6 col-lg-6 mb-3">
                                <h5 className="mb-0">Revenue</h5>
                                <b className="primary">{row.organisation.country.currency} {func.numberFormat(row.financial.revenue || 0, 2)}</b>
                            </div>
                            <div className="col-6 col-lg-6 mb-0">
                                <h5 className="mb-0">Cash out</h5>
                                <b className="text-muted">{row.organisation.country.currency} {func.numberFormat(row.financial.cashout, 2)}</b>
                            </div>
                            <div className="col-6 col-lg-6 mb-0">
                                <h5 className="mb-0">Revenue Left</h5>
                                <b className="text-muted">{row.organisation.country.currency} {func.numberFormat(row.financial.revenue_left, 2)}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EventCard;