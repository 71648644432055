import React from 'react';
import { connect } from 'react-redux';
import { Header } from 'partials';
import { func } from 'utils';

const MainContainer = (props) => {
    const { _utils: { pageTitle } } = props;

    React.useEffect(() => {
        window.init();
    }, []);


    return (
        <React.Fragment>
            <Header {...props} />

            <div className="page-title hide">
                <div className="container">
                    <div className="row">
                        <div className="col-5">
                            <h1>{pageTitle}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-body">
                <div className="container">
                    {props.children}
                </div>
            </div>

            <div className="footer text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <p>&copy; Copyright 2021 FayaVotes. All rights reserved. v{func.app.version}</p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modal fade creat-event" id="creat-event">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>CREATE EVENT</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-xl-7">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="m-t-20">TITLE</label>
                                            <input type="text" className="form-control" placeholder="Music Awards" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="m-t-20" htmlFor="exampleFormControlTextarea">DESCRIPTION</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea" placeholder="In eu urna enim. Cras hendrerit ullamcorper malesuada. In justo lacus, pharetra nec imperdiet sed, congue at metus. Mauris eleifend nec neque in pretium. Nulla eleifend, enim ultrices ultrices ullamcorper."
                                                rows="4"></textarea>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-3">
                                            <label className="m-t-20" htmlFor="exampleFormControlTextarea">DAY</label>
                                            <div className="input-group clockpicker">
                                                <input type="text" className="form-control" value="15 June 2020" />
                                                <span className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="fa fa-clock-o"></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label className="m-t-20" htmlFor="exampleFormControlTextarea">HOUR</label>
                                            <div className="input-group clockpicker">
                                                <input type="text" className="form-control" value="10 am" />
                                                <span className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="fa fa-clock-o"></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label className="m-t-20" htmlFor="exampleFormControlTextarea">MINUTE</label>
                                            <div className="input-group clockpicker">
                                                <input type="text" className="form-control" value="15 m" />
                                                <span className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="fa fa-clock-o"></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label className="m-t-20" htmlFor="exampleFormControlTextarea">DURATION</label>
                                            <div className="input-group clockpicker">
                                                <input type="text" className="form-control" value="2 h 45 m" />
                                                <span className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="fa fa-clock-o"></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="m-t-20" htmlFor="exampleFormControlTextarea">LOCATION</label>
                                            <div className="input-group clockpicker">
                                                <input type="text" className="form-control b-r-0" value="New York City" />
                                                <span className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="fa fa-crosshairs"></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-5">
                                    <label className="m-t-20">ADD SPONSORS</label>
                                    <form className="search-area" action="#" method="post">
                                        <input type="text" className="form-control" placeholder="Search Location" />
                                        <i className="fa fa-search"></i>
                                    </form>

                                    <div className="row">

                                        <div className="col-md-12">
                                            <label className="m-t-20" htmlFor="exampleFormControlTextarea">ADD GUEST</label>
                                            <div className="input-group clockpicker">
                                                <input type="text" className="form-control b-r-0" value="Search location" />
                                                <span className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="fa fa-clock-o"></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="m-t-20" htmlFor="val-skill">SET REMINDER</label>
                                            <select className="form-control" id="val-skill" name="val-skill">
                                                <option value="">Please select</option>
                                                <option value="html">HTML</option>
                                                <option value="css">CSS</option>
                                                <option value="javascript">JavaScript</option>
                                            </select>
                                        </div>
                                    </div>

                                    <button className="btn btn-danger m-t-50">CREATE EVENT</button>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    _auth: state.auth,
    _utils: state.utils,
});

export default connect(mapStateToProps, {})(MainContainer);