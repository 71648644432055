import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { axius, func } from './utils';
import { Loading } from './components';

import Main from './screens/Main';
import Authenticate from './screens/Authenticate';

// ::: store
import * as authAct from './store/auth/_authActions';
import * as utilsAct from './store/utils/_utilsActions';

const App = (props) => {
  const { _auth: { authenticated, logg }, _data: { organisation } } = props;

  const [loading, setLoading] = React.useState(true);
  // const [available, setAvailable] = React.useState(true);


  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      axius.get(`users/${logg.uuid}`),
      axius.get(`settings`),
      axius.get(`settings/countries`),
      axius.get(`settings/headers`),
    ]).then(res => {
      if ((res[0] || {}).status === 200) {
        props.profileUpdated(res[0].data);
        if (res[0].data.organisations.length > 0) {
          if (!organisation.uuid) {
            props.setSetSettings('organisation', res[0].data.organisations[0]);
          }
        } else {
          props.setSetSettings('organisation', {});
        }
      } else {
        logg.uuid && props.signOutSuccess();
      }
      res[1].status === 200 && props.setSetSettings('settings', res[1].data);
      res[2].status === 200 && props.setSetSettings('countries', res[2].data);
      
      if (func.versionBreak(res[1].data.gen.app_version) > func.versionBreak(func.app.version)) {
        console.log(`new Version: ${res[1].data.gen.app_version}`);
        caches.keys().then(keys => {
          Promise.all(keys.map(key => caches.delete(key))).then(() => {
            window.history.forward(1);
            window.location.reload(true);
          });
        });
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <React.Suspense fallback={<Loading />}>
        <Router>
          {authenticated ? (<Route render={() => <Main />} />) : (<Authenticate />)}
        </Router>
      </React.Suspense>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  _auth: state.auth,
  _data: state.data,
  _utils: state.utils,
});

const mapDispatchToProps = (dispatch) => ({
  signInSuccess: (token, data) => {
    dispatch(authAct.signInSuccess(token, data));
  },
  setSetSettings: (key, value) => {
    dispatch(utilsAct.setSetSettings(key, value));
  },
  signOutSuccess: () => {
    dispatch(authAct.signOutSuccess());
  },
  profileUpdated: (data) => {
    dispatch(authAct.profileUpdated(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);