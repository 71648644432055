import { SIGNING_IN, SIGNIN_SUCCESS, SIGNOUT_SUCCESS, UPDATE_PROFILE } from '../_types';
import * as func from 'utils/functions';

const user = func.getStorageJson('user');
const token = func.getStorage('token');

const initialState = {
  logg: user,
  authenticated: token && user.uuid ? true : false,
  token,
  signingIn: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;

    case SIGNING_IN:
      return {
        ...state,
        signingIn: action.payload,
      };

    case SIGNIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        logg: action.payload.data,
        token: action.payload.token,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        logg: {},
        authenticated: false,
        token: '',
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        logg: action.payload,
      };
  }
};

export default authReducer;
