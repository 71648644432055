import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { Header } from 'partials';
import { history } from 'store/_store';
import { func } from 'utils';
import { Loading } from 'components';

// ::: store
import * as authAct from 'store/auth/_authActions';
import * as utilsAct from 'store/utils/_utilsActions';


// ::: screens
const Awards = React.lazy(() => import('./Awards'));
const Events = React.lazy(() => import('./Events'));
const Profile = React.lazy(() => import('./Profile'));
const Activate = React.lazy(() => import('./Authenticate/Activate'));
const Developers = React.lazy(() => import('./Developers'));
const Invitation = React.lazy(() => import('./Authenticate/Invitation'));
const Organisations = React.lazy(() => import('./Organisations'));


const MainContainer = (props) => {
    const { _auth: { logg } } = props;

    React.useEffect(() => {
        window.init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const routes = [
        // { path: '/', exact: true, component: Dashboard, org: true, },
        { path: '/awards', exact: false, component: Awards, org: true, },
        { path: '/organisations', exact: false, component: Organisations, org: false, },
        { path: '/profile', exact: false, component: Profile, org: false, },
        { path: '/developers', exact: false, component: Developers, org: false, },
        { path: '/invitations/:code', exact: false, component: Invitation, org: false, },
        { path: '/signup/:code', exact: false, component: Activate, org: false, },
        { path: '/events', exact: false, component: Events, org: true, },
    ].filter(rt => rt.org === (logg.organisations.length > 0 ? true : false) || rt.org === false);

    return (
        <React.Fragment>
            <ConnectedRouter history={history}>
                <div style={{ minHeight: 'calc(100vh - 80px)' }}>
                    <Header {...props} />

                    <div className="content-body">
                        <div className="container">
                            <React.Suspense fallback={<Loading />}>
                                <Switch>
                                    {routes.map((row) => (
                                        <Route key={row.path} path={row.path} exact={row.exact} render={(p) => <row.component {...props} {...p} />} />
                                    ))}

                                    <Redirect to={routes[0].path} />
                                </Switch>
                            </React.Suspense>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>

                <div className="footer pt-4 text-center mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <p>
                                    <a href="https://anchoratechs.com" target="_blank" rel="noopener noreferrer">Anchora Technologies Ltd</a> &copy; &nbsp;
                                    Copyright 2021 FayaVotes. &nbsp; All rights reserved. &nbsp; v{func.app.version}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </ConnectedRouter>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    _auth: state.auth,
    _data: state.data,
    _utils: state.utils,
    _router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
    signInSuccess: (token, data) => {
        dispatch(authAct.signInSuccess(token, data));
    },
    setSetSettings: (key, value) => {
        dispatch(utilsAct.setSetSettings(key, value));
    },
    signOutSuccess: (path) => {
        dispatch(authAct.signOutSuccess(path));
    },
    profileUpdated: (data) => {
        dispatch(authAct.profileUpdated(data));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);