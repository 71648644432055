import axios from 'axios';
import { func } from '.';
import moment from 'moment';

// ::: axius configuration
export const config = {
    local: {
        url: 'http://localhost/fayavotes/api/',
        web: 'http://localhost/fayavotes/web/',
        apiKey: 'PJkYEfGg9kuQIDFCRt8GU9w68RTpUUwQrCXZXzNLqK8RvY7tnW6CPtjXf2Rg9aeh',
        platform: 'localhost',
    },
    dev: {
        url: 'https://qa-api.fayavotes.com/',
        web: 'https://qa.fayavotes.com/',
        apiKey: 'nLmMGr9EcNbI6mUymHsEYtZTmUwGS9ZzSQVJWnHcXUyyNXfx7Ec4ve5z5raQ3k3L',
        platform: 'qa-app',
    },
    live: {
        url: 'https://api.fayavotes.com/v1/',
        url2: 'https://votes-api.anchoratechs.com/v1/',
        web: 'https://fayavotes.com/',
        apiKey: 'tdqeBgSx5CqfpGzKHwbQMPS1e7IGSdmBMIhbR6VgFsBRz4zkWGjYS6kbFgv2hCrP',
        platform: 'app',
    },
};

const signOutSuccess = (path = '/') => {
    func.delStorage('user');
    func.delStorage('token');
    func.delStorage('awardRow');
    func.delStorage('organisation');
    func.delStorage('organisationRow');
    func.redirect(path);
};

const apnData = (obj) => {
    const body = new FormData();
    for (var p in obj) {
        if (p === 'file') {
            body.append('file[0]', obj[p]);
        } else if (p === 'image') {
            body.append('image[0]', obj[p]);
        } else {
            body.append(p, obj[p]);
        }
    }
    return body;
}

const apiHeaders = (req, type = '') => {
    func.initialize();
    const token = func.getStorage('token');
    const navigator = typeof window !== 'undefined' ? window.navigator && window.navigator.userAgent : moment().unix().toString();
    const auth = {
        time: moment().unix(),
        payload: req.data,
        endpoint: req.action,
        navigator,
    };
    if (type === 'file') {
        return {
            'Accept': 'application/json',
            'Platform': `${config[func.config.env].platform}/${func.app.version}`,
            'x-access-token': `${func.encode(auth)}.${token}`,
        };
    } else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Platform': `${config[func.config.env].platform}/${func.app.version}`,
            'x-access-token': `${func.encode(auth)}.${token}`,
        };
    }
}


export const post = async (action, data = {}) => {
    let headers = apiHeaders({ action, data });
    let empty = action.includes('http:') || action.includes('https:') ? true : false;
    let url = ((empty === false) ? config[func.config.env].url + action : action);
    try {
        let response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const get = async (action, data = {}) => {
    let headers = apiHeaders({ action, data });
    let empty = action.includes('http:') || action.includes('https:') ? true : false;
    let url = ((empty === false) ? config[func.config.env].url + action : action);
    return axios({
        method: 'GET', url,
        headers: empty ? {} : headers,
        params: data
    }).then(response => {
        return respunse(response.data);
    }).catch(error => {
        return { status: 606, result: 'Network request failed', error };
    });
}

export const put = async (action, data = {}) => {
    let headers = apiHeaders({ action, data });
    let empty = action.includes('http:') || action.includes('https:') ? true : false;
    let url = ((empty === false) ? config[func.config.env].url + action : action);
    try {
        let response = await fetch(url, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const delte = async (action, data = {}) => {
    let headers = apiHeaders({ action, data });
    try {
        let response = await fetch(config[func.config.env].url + action, {
            method: 'DELETE',
            headers,
            body: JSON.stringify(data),
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const postFile = async (action, data = {}) => {
    let headers = apiHeaders({ action, data }, 'file');
    try {
        let response = await fetch(config[func.config.env].url + action, {
            method: 'POST',
            headers,
            body: apnData(data),
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

const respunse = (res) => {
    if (res.status === 505 && (func.getStorageJson('user') || {}).uuid) {
        return signOutSuccess();
    } else if (res.token) {
        func.setStorage('token', res.token);
    }
    return res;
}