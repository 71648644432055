import { Input, Modal, notification } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { axius, func } from 'utils';
import { Navigation } from '.';

const Header = props => {

    const { _data: { organisation, eventsAvailable }, _auth: { logg } } = props;

    const [q, setSearch] = React.useState('');
    const [switchOrg, setSwitchOrg] = React.useState(false);

    const activateNow = () => {
        Modal.confirm({
            icon: null,
            title: 'Activate my account',
            content: <span>An activation procedure has been sent to <b className="primary">{logg.email}</b>. <br /> Click on the link in the mail to verify and activate this account.</span>,
            okText: 'Resend code',
            cancelText: 'Okay',
            cancelButtonProps: { className: 'show' },
            onOk: () => {
                func.loading('please wait...').then(loading => {
                    axius.post(`authenticate/activate`, { user: logg.uuid }).then(res => {
                        loading.destroy();
                        if (res.status === 200) {
                            notification.success(res);
                        } else {
                            notification.error(res);
                        }
                    });
                });
            }
        });
    }

    const organisations = q ? logg.organisations.filter(org => org.name.toLowerCase().includes(q.toLowerCase())) : logg.organisations;

    return (
        <React.Fragment>
            <div className="header">
                <div className="container">
                    {logg.status === 0 && (
                        <div className="alert alert-danger text-center mt-2 p-2">
                            You have not activated your account. Please <b className="pointer" onClick={activateNow}>activate now</b>!
                        </div>
                    )}
                    <div className="row">
                        <div className="col-lg-8 col-6">
                            <div className="logo" style={{ width: isMobile ? '85%' : '' }}>
                                <a href="./"><img src="assets/img/logo.png" alt="FayaVotes" style={{ maxWidth: isMobile ? '100%' : 'none', height: isMobile ? '' : 42 }} /></a>
                            </div>
                            {eventsAvailable.includes(organisation.country.code) && (
                                <span className="nav-control">
                                    <i className="fa fa-bars"></i>
                                </span>
                            )}
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-6 col-sm-6 hide">
                            {/* <div className="header-search">
                                <form action="#">
                                    <div className="form-group">
                                        <i className="icofont icofont-search"></i>
                                        <input type="text" className="form-control" placeholder="Search" />
                                    </div>
                                </form>
                            </div> */}
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6 hide">
                            {/* <a href="index.html#" className="btn btn-danger create-event-btn" data-toggle="modal" data-target="#creat-event">Create New Event</a> */}
                        </div>
                        <div className="col-lg-4 col-6">
                            <div className="header-user-profile">
                                <div className="dropdown">
                                    <div data-toggle="dropdown" className="pointer">
                                        <div
                                            className="bg-img img-circle float-right"
                                            style={{ backgroundImage: `url(${organisation.logo_link || 'assets/img/icon.png'})`, height: 42, width: 42, display: 'inline-block' }}
                                        />
                                        <div className="mr-2 mb-0 float-right">
                                            <div className="pt-2s text-capitalize"><b>{logg.name}</b></div>
                                            <div className="primarys">({organisation.name || 'N/A'})</div>
                                        </div>
                                        <div className="clearfix" />
                                        {/* <img src={organisation.logo_link} alt="" height="50px" /> */}
                                    </div>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        {logg.organisations.length > 1 && (
                                            <>
                                                <div className="dropdown-item pointer" onClick={() => setSwitchOrg(true)}>
                                                    <i className="fa fa-refresh text-muted" /> &nbsp; Switch organisation
                                                </div>
                                                <div className="divider py-0 my-2" />
                                            </>
                                        )}
                                        <Link className="dropdown-item" to="/profile">
                                            <i className="fa fa-user text-muted" /> &nbsp; My Profile
                                        </Link>
                                        <Link className="dropdown-item" to="/organisations">
                                            <i className="fa fa-building text-muted" /> &nbsp; Manage organisation{logg.organisations.length > 1 ? 's' : ''} <span className="badge badge-danger">{logg.organisations.length}</span>
                                        </Link>
                                        {/* <Link className="dropdown-item" to="/developers">
                                            <i className="fa fa-code text-muted" /> &nbsp; Developers
                                        </Link> */}
                                        <div className="divider py-0 my-2" />
                                        <div className="dropdown-item pointer" onClick={() => props.signOutSuccess()}>
                                            <i className="fa fa-power-off text-muted" /> &nbsp; Logout
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {eventsAvailable.includes(organisation.country.code) && (
                <Navigation {...props} />
            )}

            <Modal
                open={switchOrg}
                title="Choose an organisation to switch into"
                width={400} footer={null} closable={true} maskClosable={false}
                onCancel={() => setSwitchOrg(false)}
            >
                {logg.organisations.length > 7 && (
                    <Input size="large" placeholder="Search organisation..." autoFocus className="mb-3" onChange={e => setSearch(e.target.value)} />
                )}

                <div id="organisation">
                    {organisations.map(org => (
                        <div key={org.uuid} className={`d-flex flex-row justify-content-s mb-2 pointer p-2 ${organisation.uuid === org.uuid ? 'active' : ''}`} onClick={() => {
                            if (organisation.uuid !== org.uuid) {
                                props.setSetSettings('organisation', org);
                                window.location.replace('/');
                            }
                        }}>
                            <div>
                                <div className="bg-img img-circle" style={{ backgroundImage: `url(${org.logo_link})`, height: 35, width: 35, }} />
                            </div>
                            <div className="pl-3">
                                <div>{organisation.uuid === org.uuid && (<i className="fa fa-check-circle primary" />)} <b>{org.name}</b></div>
                                <div><small className="text-muted">{org.email}</small></div>
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>

        </React.Fragment>
    );
}


export default Header;