import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = props => {

    const path = props._router.location.pathname || '';
    const root = path.split('/');

    const { _data: { navigation, organisation, eventsAvailable }, } = props;

    return (
        <React.Fragment>
            <div className="menu">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <nav className="topbar-nav">
                                <ul className="metismenu" id="metismenu">
                                    {navigation.filter(nav => (nav.code !== 'evt' || (nav.code === 'evt' && eventsAvailable.includes(organisation.country.code)))).map(nav => (
                                        nav.subs.length === 0 ? (
                                            <li key={nav.link}>
                                                <Link to={`/${nav.link}`} className={root[1] === nav.link ? 'active' : ''}>
                                                    <span className={nav.icon}></span> {nav.name}
                                                </Link>
                                            </li>
                                        ) : (
                                            <li key={nav.link}>
                                                <a className="has-arrow" href="#!">
                                                    <span className={nav.icon}></span> {nav.name}
                                                </a>
                                                <ul>
                                                    {nav.subs.map(sub => (
                                                        <li key={sub.link}>
                                                            <Link to={`/${sub.link}`} className={root[1] === sub.link ? 'active' : ''}>{sub.name}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        )
                                    ))}
                                    <li />
                                    <li />
                                    <li />
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default Navigation;