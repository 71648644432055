import React from 'react';
import { Button, Dropdown } from 'antd';
import { func } from 'utils';


const AwardCard = (props) => {
    const { row, gotoPage, deleteRow, _data: { settings } } = props;

    return (
        <React.Fragment>
            <div key={row.uuid} className="col-lg-4">
                <div className="card event-card animate__animated animate__fadeIn">
                    <div className="card-header">
                        <div className="media d-flex justify-content-between align-items-center">
                            <div className="media-body text-truncate">
                                <h3 className="mt-0 text-truncate">{row.name}</h3>
                                <p>
                                    {row.status ? 'Published' : 'Not published'} &nbsp;•&nbsp;&nbsp;
                                    <span>{row.preferences.norm_vote_fee || settings[row.organisation.country.code].norm_vote_fee}% service fee</span> &nbsp;•&nbsp;&nbsp;
                                    <b className="primary">{row.code}</b>
                                </p>
                            </div>
                            <Dropdown trigger={['click']} placement="bottomRight" menu={{
                                items: [
                                    {
                                        key: 'edit',
                                        label: (<div onClick={() => gotoPage(row)}>Edit </div>),
                                    },
                                    {
                                        key: 'view', status: [1],
                                        label: (<div onClick={() => window.open(row.url, '_blank')}>View</div>),
                                    },
                                    {
                                        key: 'categories',
                                        label: (<div onClick={() => gotoPage(row, '/categories')}>Categories </div>),
                                    },
                                    {
                                        key: 'nominees',
                                        label: (<div onClick={() => gotoPage(row, '/nominees')}>Nominees </div>),
                                    },
                                    {
                                        key: 'nominations',
                                        label: (<div onClick={() => gotoPage(row, '/nominations')}>Nominations</div>),
                                    },
                                    {
                                        key: 'bulk',
                                        label: (<div onClick={() => gotoPage(row, '/bulk-prices')}>Bulk Voting</div>),
                                    },
                                    {
                                        key: 'stages',
                                        label: (<div onClick={() => gotoPage(row, '/stages')}>Stages</div>),
                                    },
                                    {
                                        key: 'votes',
                                        label: (<div onClick={() => gotoPage(row, '/votes')}>Votes</div>),
                                    },
                                    {
                                        key: 'results',
                                        label: (<div onClick={() => gotoPage(row, '/results')}>Results</div>),
                                    },
                                    {
                                        key: 'transfer',
                                        label: (<div onClick={() => gotoPage(row, '/transfer')}>Transfer</div>),
                                    },
                                    {
                                        key: 'payments',
                                        label: (<div onClick={() => gotoPage(row, '/payments')}>Payments</div>),
                                    },
                                    {
                                        key: 'delete', votes: 0,
                                        label: (<div className="danger" onClick={() => deleteRow(row)}>Delete</div>),
                                    },
                                ].filter(item => typeof item.votes === 'undefined' || item.votes === +row.votes)
                            }}>
                                <Button type="ghost" shape="circle" icon={<i className="fa fa-ellipsis-h"></i>} />
                            </Dropdown>
                        </div>
                    </div>

                    <div className="event-card-img">
                        <div className="bg-img" style={{ backgroundImage: `url(${row.logo_link})`, width: '100%', height: 300 }} />
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6 col-lg-6 mb-3">
                                <h5 className="mb-0">Pricing type</h5>
                                <p className="text-capitalize">
                                    {row.preferences.pricing}&nbsp;
                                    {row.preferences.pricing === 'paid' && `(${row.organisation.country.currency} ${func.numberFormat(row.price, 2)})`}
                                    {row.preferences.pricing === 'social' && `(every ${row.preferences.frequency}hrs)`}
                                </p>
                            </div>
                            <div className="col-6 col-lg-6 mb-3">
                                <h5 className="mb-0">Showing results</h5>
                                <p>{row.preferences.show_results ? 'Yes' : 'No'}</p>
                            </div>
                            <div className="col-6 col-lg-6 mb-3">
                                <h5 className="mb-0">Votes</h5>
                                <b className="text-muted">{func.numberFormat(row.votes)}</b>
                            </div>
                            <div className="col-6 col-lg-6 mb-3">
                                <h5 className="mb-0">Revenue</h5>
                                <b className="primary">{row.organisation.country.currency} {func.numberFormat(row.financial.revenue, 2)}</b>
                            </div>
                            <div className="col-6 col-lg-6 mb-0">
                                <h5 className="mb-0">Cash out</h5>
                                <b className="text-muted">{row.organisation.country.currency} {func.numberFormat(row.financial.cashout, 2)}</b>
                            </div>
                            <div className="col-6 col-lg-6 mb-0">
                                <h5 className="mb-0">Revenue Left</h5>
                                <b className="text-muted">{row.organisation.country.currency} {func.numberFormat(row.financial.revenue_left, 2)}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AwardCard;