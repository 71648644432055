import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore, { history } from './store/_store';
import { func } from './utils';

import './index.less';
import './App.scss';
import './assets/scss/custom.scss';

const store = configureStore();

Sentry.init({
  dsn: 'https://258d9577feff4e12b532a681a6cc0ff8@o507020.ingest.sentry.io/6041615',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing(),],
  release: `fayavotes-app@${func.app.version}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <Provider store={store}>
      <React.StrictMode>
        <App history={history} />
      </React.StrictMode>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

reportWebVitals();
