import './Loading.scss';

const Loading = () => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center text-center" style={{ height: '80vh' }}>
            <img src="assets/img/icon.png" alt="FayaVotes" width="100px" className="animate__animated animate__rotateOut animate__infinite" />
        </div>
    );
}


export default Loading;