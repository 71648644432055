import { Checkbox, Input } from 'antd';
import React from 'react';

const MultiChecks = ({ value, options, onChange }) => {

    const [q, setQ] = React.useState('');

    const filteredOptions = q ? options.filter(item => item.label.toLowerCase().includes(q.toLocaleLowerCase())) : options;

    return (
        <React.Fragment>
            <div id="MultiChecks">
                <div className="bg-light rounded p-3">
                    {options.length > 12 && (
                        <Input placeholder="Search..." onChange={e => setQ(e.target.value)} />
                    )}
                    <div className="row">
                        {/* <div className="col-12 col-lg-5">
                            <div className="mt-3" style={{ maxHeight: 300, overflow: 'scroll' }}>
                                {options.filter(item => value.includes(item.value)).map(item => (
                                    <div className="mb-1 text-truncate">
                                       <i className="fa fa-check-square primary" /> {item.label}
                                    </div>
                                ))}
                            </div>
                        </div> */}
                        <div className="col-12 col-lg-12">
                            <div className={`${options.length > 12 ? 'mt-3' : ''} borders roundeds p-3s`} style={{ maxHeight: 300, overflow: 'scroll' }}>
                                <Checkbox.Group
                                    value={value}
                                    options={filteredOptions}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MultiChecks;