import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as authAct from 'store/auth/_authActions';
import * as utilsAct from 'store/utils/_utilsActions';
import { func } from 'utils';

const Login = React.lazy(() => import('./Login'));
const Reset = React.lazy(() => import('./Reset'));
const Signup = React.lazy(() => import('./Signup'));
const Activate = React.lazy(() => import('./Activate'));
const Invitation = React.lazy(() => import('./Invitation'));

const routes = [
    { path: '/', component: Login, exact: true },
    { path: '/signup', component: Signup, exact: true },
    { path: '/signup/:code', component: Activate, exact: true },
    { path: '/reset', component: Reset, exact: true },
    { path: '/reset/:code', component: Reset, exact: true },
    { path: '/invitations/:code', component: Invitation, exact: true },
];

const Authenticate = props => {

    const [image, setImage] = React.useState(0);

    React.useEffect(() => {
        var i = Math.floor(Math.random() * 9);
        setImage(i);
    }, []);


    return (
        <React.Fragment>
            <main id="Authenticate" className="d-flex align-items-center min-vh-100 py-3 py-md-0">
                <div className="container">
                    <div className="card login-card">
                        <div className="row no-gutters">
                            <div className="col-md-5">
                                <img src={`assets/img/login/${image}.jpg?v=${func.app.version}`} alt="FayaVotes" className="animate__animated animate__fadeIn login-card-img" />
                                <p className="text-white font-weight-medium text-center flex-grow align-self-end footer-link text-small">
                                    Your 360° e-voting platform
                                </p>
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    {!window.location.pathname.includes('/invitations') && (
                                        <div className="brand-wrapper">
                                            <img src="assets/img/logo.png" alt="FayaVotes - Logo" className="logo" />
                                        </div>
                                    )}
                                    <Switch>
                                        {routes.map((row) => (
                                            <Route key={row.path} path={row.path} exact={row.exact} render={(p) => <row.component {...props} {...p} />} />
                                        ))}

                                        <Redirect to={routes[0].path} />
                                    </Switch>
                                    <nav className="login-card-footer-nav hide">
                                        <a href="#!">Terms of use</a> &nbsp; • &nbsp;
                                        <a href="#!">Privacy policy</a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    _auth: state.auth,
    _data: state.data,
    _utils: state.utils,
    _router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
    signInSuccess: (token, data) => {
        dispatch(authAct.signInSuccess(token, data));
    },
    signOutSuccess: (path) => {
        dispatch(authAct.signOutSuccess(path));
    },
    setSetSettings: (key, value) => {
        dispatch(utilsAct.setSetSettings(key, value));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);
