import { SET_SET_SETTINGS } from '../_types';
import * as func from 'utils/functions';

const initialState = {
    banks: func.getStorageJson('banks') || [],
    settings: func.getStorageJson('settings') || {},
    countries: func.getStorageJson('countries') || [],
    organisation: func.getStorageJson('organisation') || {},

    awardRow: func.getStorageJson('awardRow') || {},
    eventRow: func.getStorageJson('eventRow') || {},
    organisationRow: func.getStorageJson('organisationRow') || {},

    eventsAvailable: ['gh'],

    navigation: [
        // { code: 'dash', name: 'Dashboard', icon: 'fa fa-tachometer', link: '', subs: [], },
        { code: 'awa', name: 'Awards', icon: 'fa fa-star', link: 'awards', subs: [], },
        { code: 'evt', name: 'Events', icon: 'fa fa-ticket', link: 'events', subs: [], },
        { code: 'org', name: 'Organisations', icon: 'fa fa-building', link: 'organisations', subs: [], },
        // { code: 'set', name: 'Settings', icon: 'fa fa-cogs', link: 'settings', subs: [], },
    ]
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;

        case SET_SET_SETTINGS:
            return {
                ...state,
                [action.key]: action.value,
            };
    }
};


export default dataReducer;